import UnderlineLink from "@modules/common/components/underline-link"
import Image from "next/image"

const Hero = () => {
  return (
    <div className="h-screen w-full relative">
      <div className="text-white absolute inset-0 z-10 flex flex-col justify-center items-center text-center small:text-left small:justify-end small:items-start small:p-32">
        <h1 className="text-3xl-semi mb-4 drop-shadow-md shadow-black">
          Savourez un produit d&apos;exception
        </h1>
        <p className="text-lg max-w-[36rem] mb-6 drop-shadow-md shadow-black">
          Récompensé par la médaille d&apos;argent au Concours général agricole
          2023, notre{" "}
          <span className="font-bold">Miel de Maquis de Printemps</span> offre
          une saveur florale, fruitée et aromatique unique.
        </p>
        <UnderlineLink href="/store">Découvrez nos produits</UnderlineLink>
      </div>
      <Image
        src="/hero.jpg"
        fill
        loading="eager"
        priority={true}
        quality={90}
        alt="Du miel"
        className="absolute inset-0 object-cover"
        draggable="false"
      />
    </div>
  )
}

export default Hero
