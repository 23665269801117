import Head from "@modules/common/components/head"
import FeaturedProducts from "@modules/home/components/featured-products"
import Hero from "@modules/home/components/hero"
import Layout from "@modules/layout/templates"
import { ReactElement } from "react"
import { NextPageWithLayout } from "types/global"

const Home: NextPageWithLayout = () => {
  return (
    <>
      <Head
        title="Miels corses de qualité supérieure"
        description="Découvrez notre sélection de miels corses de qualité supérieure, certifiés bio et AOP Mele di Corsica. Récoltés dans les forêts et maquis de l'île de beauté, nos miels offrent des saveurs uniques et authentiques. Commandez en ligne sur notre site pour profiter d'une expérience gustative inoubliable et soutenir les producteurs locaux. Choisissez notre miel pour un choix éthique et responsable, respectueux de l'environnement et de la tradition apicole corse."
      />
      <Hero />
      <FeaturedProducts />
    </>
  )
}

Home.getLayout = (page: ReactElement) => {
  return <Layout>{page}</Layout>
}

export default Home
